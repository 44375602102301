import React from 'react'
import Layout from '../components/layout'
import Title from '../components/title'

export { Head } from '../components/head'

export default function Page({ pageContext }) {
	return (
		<Layout>
			<Title>{pageContext.title}</Title>
			<div
				className="text-xl opacity-70 md:text-2xl"
				dangerouslySetInnerHTML={{ __html: pageContext.content }}
			/>
		</Layout>
	)
}
